













































































































import Enum from "@/config/enum";
import UserModel, { UserDTO } from "@/models/UserModel";
import GeneralApi from "@/networks/GeneralApi";
import BarChart from "@/views/components/charts/BarChart";
import Period from "@/views/components/inputs/period.vue";
import { AxiosResponse } from "axios";
import moment from "moment-jalaali";
import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../../store";

@Component({
  components: {
    BarChart,
    period: Period
  }
})
export default class SuperAdminDashboard extends Vue {
  private selectedPeriod = "-30 days";
  private callCount = 0;
  private eventCount = 0;
  private userCount = 0;
  private topAdvisers = [];
  private options = Enum.ChartOptions;
  private topOffices = [];
  private chartData = {
    labels: [],
    datasets: []
  };

  mounted() {
    this.dashboard();
  }

  public async dashboard(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().dashboard({
        period: this.selectedPeriod
      });
      this.callCount = res.data.data.call_count;
      this.eventCount = res.data.data.event_count;
      this.userCount = res.data.data.user_count;
      this.topOffices = res.data.data.top_offices;
      this.chartData = {
        labels: res.data.data.my_dates.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: [
          {
            label: "تعداد رویدادهای موفق روزانه",
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            data: res.data.data.my_vals
          }
        ] as any
      };
      this.topAdvisers = res.data.data.top_advisers.map(
        (x: { cnt: number; creator: UserDTO }) => {
          return {
            cnt: x.cnt,
            user: new UserModel(x.creator)
          };
        }
      );
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.dashboard();
    }
  }
}
