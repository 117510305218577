



































































































































































































import GeneralApi from "@/networks/GeneralApi";
import store from "@/store";
import BarChart from "@/views/components/charts/BarChart";
import Period from "@/views/components/inputs/period.vue";
import DashboardComponent from "@/views/components/pages/dashboard/dashboard.vue";
import { AxiosResponse } from "axios";
import moment from "moment-jalaali";
import DatetimePicker from "vue-persian-datetime-picker";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {
    BarChart,
    period: Period,
    DashboardComponent,
    "datetime-picker": DatetimePicker
  }
})
export default class AdminDashboard extends Vue {
  private user = {};
  private reminderItem: Record<string, any> = {};
  private today = moment().format("YYYY-MM-DD");
  private datePopup = false;
  private bms = process.env.VUE_APP_BMS;
  private deleteReminderDialog = false;
  private updateReminderDialog = false;
  selectedPeriod = "-30 days";
  data = {
    events: []
  };
  private errors: Record<string, Array<string>> = {};

  mounted() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user ?? "");
    this.dashboard();
    setInterval(() => {
      this.dashboard();
    }, 300000);
  }
  public async dashboard(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().dashboard({
        period: this.selectedPeriod
      });
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
  public async deleteReminder(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().deleteReminder(
        {
          explanation: this.reminderItem.explanation
        },
        this.reminderItem.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.deleteReminderDialog = false;
      this.reminderItem = {};
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async updateReminder(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().updateReminder(
        {
          explanation: this.reminderItem.explanation,
          date: this.reminderItem.date
        },
        this.reminderItem.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.updateReminderDialog = false;
      this.reminderItem = {};
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.dashboard();
    }
  }
}
