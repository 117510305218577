

















import { Component, Vue } from "vue-property-decorator";
import UserDashboard from "@/views/pages/Home/dashboard/user.vue";
import SuperAdminDashboard from "@/views/pages/Home/dashboard/super-admin.vue";
import AdminDashboard from "@/views/pages/Home/dashboard/admin.vue";
import AdviserProDashboard from "@/views/pages/Home/dashboard/adviser-pro.vue";
import AdviserDashboard from "@/views/pages/Home/dashboard/adviser.vue";
@Component({
  components: {
    user: UserDashboard,
    admin: AdminDashboard,
    adviser: AdviserDashboard,
    "adviser-pro": AdviserProDashboard,
    "super-admin": SuperAdminDashboard
  }
})
export default class Dashboard extends Vue {
  private roles = localStorage.getItem("roles") || [];
  mounted() {
    console.log(this.roles);
  }
}
