




































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import GeneralApi from "@/networks/GeneralApi";
import store from "../../../../store";
import { AxiosResponse } from "axios";
import BarChart from "@/views/components/charts/BarChart";
import Period from "@/views/components/inputs/period.vue";
import moment from "moment-jalaali";

@Component({
  components: {
    BarChart,
    period: Period
  }
})
export default class TestPanel extends Vue {
  private selectedPeriod = "-30 days";
  private data = {
    user: {
      avatar: "",
      mobile: "",
      id: "",
      fname: "",
      lanme: ""
    }
  };
  private weekday: any = [];

  mounted() {
    this.dashboard();
    this.generateWeek();
  }

  dayMoment(item: string): string {
    return moment(item).format("jD");
  }

  monthMoment(item: string): string {
    return moment(item).format("jMMMM");
  }

  generateWeek(): void {
    let week = [];
    for (let i = 0; i < 7; i++) {
      week.push(
        moment().startOf("week").isoWeekday(6).add(i, "day").toISOString()
      );
    }

    this.weekday = week;
  }

  public async dashboard(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().dashboard({
        period: this.selectedPeriod
      });
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.dashboard();
    }
  }
}
