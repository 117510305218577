





































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "@/store";
import Enum from "@/config/enum";
import { EventApi } from "@/networks/EventApi";
import { UserApi } from "@/networks/UserApi";
import DatetimePicker from "vue-persian-datetime-picker";
import eventsDialog from "@/views/components/pages/dashboard/eventsDialog.vue";

@Component({
  components: { "datetime-picker": DatetimePicker, eventsDialog }
})
export default class Dashboard_component extends Vue {
  search: any = "";
  private roles = localStorage.getItem("roles") || [];
  dialog = false;
  cancelDialog = false;
  canNotBeContract = 0;
  private eventStatus = Enum.eventStatusAdmin;
  private errors: Record<string, Array<string>> = {};
  dialogItem: Record<string, any> = {};
  @Prop({ default: [] })
  events: any;

  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  }

  refreshList() {
    this.dialogItem = {};
    this.$emit("refresh-list");
  }

  get SearchEvents() {
    let items = this.events;
    this.$router.push({ query: { search: this.search } });
    if (this.search) {
      items = items.filter(
        (x: any) =>
          x.user.fname.includes(this.search) ||
          x.user.lname.includes(this.search)
      );
    }
    return items;
  }

  public async remindLater(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().remindLaterForContract(
        {
          explanation: this.dialogItem.explanation,
          date: this.dialogItem.date
        },
        this.dialogItem.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("refresh-list");
      this.dialog = false;
      this.dialogItem = {};
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public async cancelEvent(): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().changeStatus(
        {
          is_done: 2,
          summery: this.dialogItem.summery
        },
        this.dialogItem.id
      );
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.$emit("refresh-list");
      this.dialog = false;
      this.dialogItem = {};
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  public setDialog(item: Record<string, any>): void {
    this.dialogItem = item;
    this.dialog = true;
  }

  copyPhone(phone: string): void {
    navigator.clipboard.writeText(phone).then(() => {
      store.commit("showError", {
        message: "لینک با موفقیت کپی شد ",
        color: "danger"
      });
    });
  }
}
